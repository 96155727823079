import Vue from 'vue';

// State object
const state = {    
    groups:[
    ],
    leaderboard:[
    ],
    // statusoverview: {

    // }
}
// Actions 
const actions = {
    reset({commit}) {
        commit('RESET');
    },
    removeGroup({commit}, socketid) {
        commit('REMOVE_GROUP',socketid);
    },
    updateGroup({commit}, group) {
        commit('UPDATE_GROUP',group);
    },
    // testSort({commit}, ) {
    //     commit('TEST_SORT');
    // },
}
// Mutations
const mutations = {
    RESET(state) {
        state.groups = [];
        state.leaderboard = [];
        // statusoverview = [];

    }, 
    REMOVE_GROUP(state, socketid) {
        // do no really remove the group, but make it inactive, so the group indexes 
        // never change and a group that logs in again gets it's original index 
        //let index = 

        let index = state.groups.findIndex( group => group.socketid === socketid );
        let group = state.groups[index];
        
        if (group) {
            //state.groups.find( group => group.socketid === socketid );
            //group.status = 'offline';
            group.online = false;

            Vue.set(state.groups, index, group);        
        }

        console.log("group removed")

        //Vue.delete(state.groups, socketid);
    },
    UPDATE_GROUP(state, obj) {
        //state.groups.find( group => group.groupname === obj.groupname );

        let index = state.groups.findIndex( group => group.groupname === obj.groupname );
        
      //  console.log(index)
        // group not yet present, add it to the list with its own index
        if (index < 0) {
            // this is a new group, create it first
            let group = obj;
            //group.status = 'online';
    
            state.groups.push(group)
            group.index = Object.keys(state.groups).length;
            group.status = (obj.status)?obj.status : '';

            index = state.groups.findIndex( group => group.groupname === obj.groupname );
        }

        //else {
        let group = state.groups[index];

        // set the group status to online, it might be a lost group that logs in again
        group.online = true;

        //console.log(group)
        // socketid might have changed in that case
        group.socketid  = obj.socketid;
        group.score     = obj.score;
        group.action    = obj.action; 

        switch (obj.action) {
            case "locationUpdate" : {
                group.location = obj.location;
                break;
            }
            case "gameUpdate" : {
                group.status = obj.status;
                group.round  = obj.round;
                break;
            }
            case "endgameUpdate" : {
                group.status   = obj.status;
                group.player   = obj.player;
                break;
            }
        }

        Vue.set(state.groups, index, group);        
        // }

        // sort groups by score
        state.leaderboard = Array.from( state.groups );
        state.leaderboard.sort((a, b) => b.score - a.score || a.groupname.localeCompare(b.groupname) );

        // sort groups by status
        // state.statusoverview = Array.from( state.groups );
        // state.statusoverview.sort((a, b) => a.status.localeCompare(b.status) || a.player - b.player );


        // Vue.set(state.groups, group.socketid, group);        
    },
    // TEST_SORT(state) {
    //     // state.statusoverview = Array.from( state.groups );
    //     // state.statusoverview.sort((a, b) => a.status.localeCompare(b.status) || a.player - b.player );
    //     state.groups.forEach(group => {
    //         group.status = 'finished'; 
    //     });

    //     state.leaderboard = Array.from( state.groups );
    //     state.leaderboard.sort((a, b) => b.score - a.score || a.groupname.localeCompare(b.groupname) );
    // }
}

const getters = {
    groups: state => {
        return state.groups;
    }, 
    leaderboard: state => {
        return state.leaderboard;
    },  
    allgroupsFinishedEndgame: state => {
        if (state.groups.length > 0) {
            return state.groups.every(group => {
                if (group.status != "finished") {
                  return false;
                }
              
                // Make sure you return true. If you don't return a value, `every()` will stop.
                return true;
              });    
        }
        else {
            return false;
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}