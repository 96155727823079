<template>
    <div v-if="(message != '')" class="panel">
        <div class="backdrop"></div>        
        <div class="popup" :class="id">
            <div class="panel-left">
                <div class="message" v-html="message">

                </div>
            </div>
            <div class="panel-right">
                <app-button class="button-ok" color="purple" bevel="true" :caption="$t('button-ok')"  @click="$emit('ok',id);" />
                <app-button class="button-cancel"  color="purple" bevel="false" :caption="$t('button-cancel')"  @click="$emit('cancel');" />
            </div>
        </div>    
    </div>
</template>

<script>

import AppButton from  "@/components/ui/AppButton.vue";

export default {
    name: "ConfirmPanel",
    components: {
      AppButton,
    },    
    data() {
        return {
            message : '',
            id: ''
        };
    },
    methods: {
        show(id){
            this.message = this.$i18n.t('confirm-message-' + id);
            this.id = id;
        },
        hide() {
            this.message = '';
        },
        handleOk() {

        },
    },
    mounted() {

    },
    computed: {
       
       
    },
};
</script>


<style scoped>

.panel {
    width: 100%;
    height: 100%;   
}

.backdrop {
    position: absolute;
    background-color: black;
    opacity: 0.4;
    width: 100%;
    height: 100%;   
    z-index: 1;
}

.popup {
    position: absolute;
    background-color: var(--green-middle-color);
    width: 100%;
    top: calc(190px / var(--scale) ); 
    height: calc(380px / var(--scale) );    
    z-index: 3;
    display: flex;
    flex-direction: row;
}

.panel-left {
    width:75%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message {
    /* margin-left: calc(-150px / var(--scale) );  */
    width: calc(700px / var(--scale) );
    color: var(--purple-dark-color); 
    font: calc(35px / var(--scale) )/calc(50px / var(--scale) ) Fira Sans Bold;   
}

.panel-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.button-ok {

}

.button-cancel {
    margin-top: 25px;
    margin-left: 9px;
}

</style>