import axios from 'axios'
//import Vue from 'vue';

// State object
const state = {
    isMaster: false,
    groupname:'',
    roomname:'',
    isResuming: false,
    isInVWNW: false,
}
// Getter functions
const getters = {
    isMaster: state => {
        return state.isMaster;
    },
    isResuming: state => {
        return state.isResuming;
    },
    isInVWNW: state => {
        return state.isInVWNW;
    },
    groupname : state => {
        return state.groupname;
    },
    roomname : state => {
        return state.roomname;
    },    
}
// Actions 
const actions = {
    reset({commit}){
        commit('RESET_USER');
    },
    setResuming({commit},bool) {
        commit('SET_RESUMING', bool);
    },
    setVWNWmode ({commit},bool) {
        commit('SET_VWNW_MODE', bool);
    },
    setUserType ({commit},usertype) {
        commit('SET_USER_TYPE', usertype);
    },
    setRoomname({commit},roomname) {
        commit('SET_ROOM_NAME',roomname);
    },
    setGroupname({commit,state},groupname) {
        // there is no set groupname. Create a group implicitly
        // by setting a state for this groupnae with assignmentIndex 'init'
        let payload =  {
            id: 0,
            correct: false,
            score: 0
        }

        let endpoint = process.env.VUE_APP_API + "results/" + state.roomname + '/' + groupname;

        // console.log(endpoint);
        // console.log(payload);

        return new Promise((resolve,reject) => {
            axios.put(endpoint, payload)
            .then(() => {
                commit('SET_GROUP_NAME',groupname);
                //commit('USER_TO_LOCALSTORAGE')
                resolve(groupname);
            })
            .catch(error => {
                console.log(error);         
                reject(error)
            })
        });        

  
    },
    resumeGroup({commit},groupname) {
        commit('SET_GROUP_NAME',groupname);        

        // try to retrieve data from localstorage
        // let localMaster = localStorage.getItem("isMaster" );
        // let localGroupname = localStorage.getItem("groupname" );
        // let localRoomname = localStorage.getItem("roomname" );

        // // console.log(typeof localMaster);
        // // console.log(state.groupname + "," + localGroupname);
        // // console.log(state.roomname + "," + localRoomname);

        // // console.log(localRoomname);

        // if (state.groupname === localGroupname &&
        //     state.roomname === localRoomname) {

        //     //console.log("restore")
        //     // we are resuming and our data is on this tablet. Sweet!
        //     //dispatch("userdata/restoreDataFromTablet",null,{root:true})
        // }
        // else {
        //     // resuming but not from this tablet, reset
        //     //console.log("reset")
        //     commit('USER_TO_LOCALSTORAGE');
        //     //dispatch("userdata/restoreDataFromServer",null,{root:true})
        // }
    },
}
// Mutations
const mutations = {
    RESET_USER(state) {
        state.isMaster   = false;
        // disabled for testing purposes
        state.groupname  = '';
        state.roomname = '';
        // state.roomname   = '';
        state.isResuming = false;
        state.isInVWNW = false;
    
        // not for now!
        //localStorage.clear();
    },
    SET_RESUMING (state, bool) { 
        state.isResuming = bool;
    }, 
    SET_USER_TYPE (state, usertype) { 
        state.isMaster = (usertype === 'begeleider');
    },     
    SET_VWNW_MODE (state, bool) { 
        state.isInVWNW = bool;
    },     
    SET_ROOM_NAME(state, roomname ){
        state.roomname = roomname;
    },
    SET_GROUP_NAME (state, groupname) {
        state.groupname = groupname;  
    },
    
    USER_TO_LOCALSTORAGE(state) {
        // not for now
       // localStorage.clear();
        console.log("--user save to localstorage--");
        try {
            localStorage.setItem("isMaster"  , state.isMaster );
            localStorage.setItem("roomname"  , state.roomname );
            localStorage.setItem("groupname" , state.groupname );
        }
        catch (e) {
            console.log("Storage failed: " + e);
        }        
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}