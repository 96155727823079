import Vue from 'vue'
import Vuex from 'vuex'

import hardware from './modules/hardware'
import clock from './modules/clock'
import gamesettings from './modules/gamesettings'
import game from './modules/game'
//import screen from './modules/screen'
import user from './modules/user'
//import socket from './modules/socket'
import groups from './modules/groups'

//import axios from 'axios'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    // header
    headerContent: {
      caption:"Dit is een testversie",
      hint:""
    },
    showHeader: true,
    showFooter: true,
    mastercode: '',
    //groups:[]
  },
  modules: {
    hardware,
    clock,
    game,
    //screen,
    user,
    //socket,
    gamesettings,
    groups
  },
  getters :{
    mastercode: state => {
      return state.mastercode
    },
    // groups: state => {
    //   return state.groups
    // },
  },
  actions: {
    // initApp ({commit}) {
    //   axios
    //   .get("static/content/settings.json")
    //   .then(response => {

    //     //console.log(response.data)

    //     commit('gamesettings/SET_POINTS_PER_QUESTION', response.data.pointsPerQuestion);

    //     // if (process.env.VUE_APP_RUN_MODE === "development") {
    //     //   commit('game/SET_ENDGAME_TIME', 1);
    //     // }
    //     // else {
    //       commit('gamesettings/SET_ENDGAME_DURATION', response.data.endgametime);
    //       commit('gamesettings/SET_ENDGAME_PLAYER_TIME',response.data.endgameplayertime);
    //       commit('gamesettings/SET_DURATION', response.data.defaulttime);
    //     // }

    //     commit('SET_HIT_DISTANCE', response.data.hitdistance);
    //     commit('SET_CODE', response.data.code);
    //     commit('SET_MARKERPOOL', response.data.markerpool);

    //     // axios
    //     // .get("static/content/contentApp.json")
    //     // .then(response => {
    //     //   commit('screen/SET_SCREENS', response.data.screens);
    //     //   commit("screen/SHOW_SCREEN", response.data.startwith);
    //     // })
  
    //   })
    // },
    setMasterCode({commit},code) {
      commit('SET_MASTERCODE',code);
    },
    setHeaderVisibility({commit},bool) {  
      commit('SET_HEADER_VISIBILITY', bool);
    },
    setFooterVisibility({commit},bool) {  
      commit('SET_FOOTER_VISIBILITY', bool);
    },
    setHeaderContent({commit},content) {  
      commit('SET_HEADER_CONTENT', content);
      // commit('SET_FOOTER_VISIBILITY', true);
      commit('SET_HEADER_VISIBILITY', true);
    },   
    // getAllGroups ({commit, state}) {
    //   // console.log("get all groups")
    //    return new Promise((resolve,reject) => {
    //      let endpoint = process.env.VUE_APP_API + state.user.roomname + "/groups"
 
    //      axios
    //        .get(endpoint)
    //        .then(r => r.data)
    //        .then(result => {
    //           // console.log("save groups")
    //            commit('REGISTER_GROUPS', result.groupNames);
    //            resolve();
    //      })
    //      .catch(error => {
    //       // console.log(error)
    //        reject(error.response.data.message)
    //      })
    //    });
    //  },     
    reset({dispatch}) {
      // commit('REGISTER_GROUPS', []); 
      //dispatch('socket/reset');      
      //dispatch('socket/closeSocket');
      dispatch("user/reset");
      dispatch("game/reset");
      dispatch("groups/reset");
      dispatch("clock/clearTimer");
      // dispatch("screen/showScreen","")
      // dispatch("screen/showScreen","setup")
     } 
  },
  mutations: {
    SET_MASTERCODE (state, code) {
      state.mastercode = code;
    },
    SET_HEADER_CONTENT (state, content) {
      state.headerContent = content;
    },
    SET_FOOTER_VISIBILITY (state, bool) {
        state.showFooter = bool;
    },
    SET_HEADER_VISIBILITY (state, bool) {
        state.showHeader = bool;
    },
    // REGISTER_GROUPS (state, groups) {
    //   state.groups = groups;
    // },
  },
  strict: debug
})
