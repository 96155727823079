<template>

  <div class="app" id="app">


    <app-header class="header" @quit="handleQuit"/>

    <!-- <screen /> -->
    <router-view />


    <confirm-panel ref="confirm" class="confirm-panel" @ok="handleConfirmOk" @cancel="handleConfirmCancel" />

    <app-footer class="footer"/>

    <div class="version-number">{{appversion}}</div>

    <!-- <error-banner color="yellow" ref="errorbanner" />

    <confirm-popup ref="confirmpopup" @submit="handleConfirm" @cancel="handleCancel" />
    <timed-confirm-popup ref="timedconfirmpopup" @submit="handleConfirm" @cancel="handleCancel" />
    <quit-keuze-popup ref="quitkeuzepopup" @submit="handleConfirm" />
    <message-popup ref="messagepopup" @submit="handleConfirm" /> -->

    <!-- dimmed screen controlled by master -->
    <div v-if="(this.$store.getters['game/dimmed'] && !this.$store.getters['user/isMaster'])" class="dimscreen"> 
      <div class="headerdim"></div>
      <div class="screendim"></div>
      <!-- <img class="button-undim"  @click="handleUnDim()"  :src="`./static/images/bt-undim-screen.png`" /> -->
      <img class="button-undim" :src="`./static/images/bt-undim.png`" @click="handleUnDim"  />

    </div>

    <!-- <password-popup ref="passwordpopup" @submit="handleConfirm" /> -->

    <!-- Screen that shows the reload message. Might also function as a generic message screen? -->
    <!-- <div v-if="reloading" class="dimscreen"> 
      <div class="headerdim fulldim"></div>
      <div class="screendim fulldim"></div>
      <div class="messagepanel">
        Even wakker worden ...
      </div>
    </div> -->

    <!-- Screen that shows the reload message. Might also function as a generic message screen? -->
    <div v-if="swUpdating" class="dimscreen"> 
      <div class="headerdim fulldim"></div>
      <div class="screendim fulldim"></div>
      <div class="messagepanel">
        <!-- Er is een nieuwe versie van de Van wens naar wet app gedetecteerd. -->
        <br/>Grondwetloop app wordt bijgewerkt <br/>
      </div>
    </div>

  </div>


</template>

<script>


import AppHeader from  "./components/AppHeader.vue";
import AppFooter from  "./components/AppFooter.vue";
import ConfirmPanel from "@/components/ui/ConfirmPanel.vue";

// import Screen from "./components/Screen.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
    ConfirmPanel  
    // Screen,
  },
  data() {
    return {
      swUpdating: false,
      dimscreen: false
    }
  },
  computed: {
    appversion() {
      return process.env.VUE_APP_VERSION;
    },
  }, 
  watch: {
  }, 
  methods: {
    showUpdatingMessage() {
      console.log("updating...");
      this.swUpdating = true;

      if (this.$store.getters["user/isInVWNW"]) {
          // pass quit to parent
          parent.postMessage("gwl-sw-updating", process.env.VUE_APP_VWNW_HOST);
      }
    },
    hideUpdatingMessage() {
      console.log("done!");
      this.swUpdating = false;

      if (this.$store.getters["user/isInVWNW"]) {
          // pass quit to parent
          parent.postMessage("gwl-sw-updated", process.env.VUE_APP_VWNW_HOST);
      }
    },
    handleQuit() {

      // are we standalone or in VWNW app?

      if (this.$store.getters["user/isInVWNW"]) {
          // pass quit to parent
          console.log( process.env.VUE_APP_VWNW_HOST )
          parent.postMessage("quit", process.env.VUE_APP_VWNW_HOST);
      }
      else {
          this.$refs.confirm.show("reset-game");
      }

      // let action = "quit";

      // if (this.isMaster) {
      //   action += '-begeleider';
      //   this.$refs.quitkeuzepopup.show(action);
      // }
      // else {
      //   this.$refs.passwordpopup.show(action);
      // }
    },
    handleConfirmOk(id) {
        console.log("OK:"  +id);
        this.$refs.confirm.hide();

        if (id === "reset-game") {
          this.$store.dispatch("reset");
          this.$router.push("/");
        }
    },
    handleConfirmCancel() {
        this.$refs.confirm.hide();
    },    
    handleUnDim() {
      console.log("UNDIM")
      //this.$refs.confirmpopup.show("undim");
      //this.$refs.passwordpopup.show("undim-leerling");
    },
},
  mounted() {
    console.log("this is it. here we go again!")
    console.log(process.env);

    //this.$store.dispatch("reset");

    // console.log(this.$store.getters['user/roomname'])
    // console.log(this.$route.params.roomname)
    // console.log(this.$route.params)

    // if (this.$store.getters['user/roomname'] === '' && !this.$route.params.roomname ) {
    //     this.$router.push("/");
    // }

    this.$store.dispatch("setFooterVisibility", false);
    
    // console.log("test sentry error")
    // throw new Error('Sentry Error');

  },
  created() {
     this.popuptimer = null;

    document.addEventListener(
      'swUpdating', this.showUpdatingMessage, { once: true }
    );
    document.addEventListener(
      'swUpdated', this.hideUpdatingMessage, { once: true }
    );
  }
};
</script>


<style>
/* html {
  overflow: hidden !important;
} */
 :root{
    --purple-dark-color: #98177C;
    --purple-middle-color: #B65CA3; 
    --purple-light-color: #E0B9D7;

    --green-dark-color: #50962E;
    --green-middle-color: #79b55b;
    
    --blue-middle-color: #7FD9F2;
    
    /* --accent-color-1: #C5BBAF;  */
    --gray-color-light: #E2DDD7;
    --gray-color-middle: #dad3cb;
    --gray-color-dark: #C5BBAF;

    --dark-text-color: #000000;
    --light-text-color: #ffffff;

    --scale: 1.33;



}

/*

width = calc(1024px / var(--scale) );

*/

* {
  margin:0;
  box-sizing: border-box; 
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

body {
  position: relative;
  min-height: 100vh;
  overscroll-behavior-y: contain;
}


.app {
  display: grid;
  width:100vw;
  height: 100vh;
  overflow: hidden;
  background-color: lightgrey;
  font-family: 'Fira Sans Regular';
}

.confirm-panel {
  position: absolute;
  width:100vw;
  height:100vh;
  z-index: 10;
  top:0;
}

.dimscreen {
  position: absolute;
  width:100vw;
  height:100vh;
  z-index: 4;
}

.headerdim {
  width:100vw;
  height: calc(83px / var(--scale) );
  background-color: black;
  opacity: 0.5;
}

.screendim {
  width:100vw;
  height:90vh;
  background-color: black;
  opacity: 1;
}


.messagepanel {
  position: absolute;
  width:50vw;
  height:30vh;
  margin-left: -25vw;
  margin-top: -15vh;
  top: 50%;
  left: 50%;
  background-color: var(--green-middle-color);
  /* border:1px solid white; */
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.header {
  position: absolute;
  width:100vw;
  height: calc(83px / var(--scale) );
  /* z-index:1; */
}

.footer {
  position: absolute;
  width:100vw;
  height: calc(43px / var(--scale) );

  /* height:32px; */
  bottom:0;
  z-index:2;
}

.version-number {
  position: absolute;
  font-family: 'Fira Sans Bold';
  color: var(--purple-dark-color);
  bottom:0;
  right:0;
  padding:5px 10px;
  z-index:2;
}

/* in use by activities */
.page {
    position: relative;
}

.main {
    position: relative;
}

/*  map icons */

</style>
