import axios from 'axios'
// import router from '@/router'
import Vue from 'vue';

// State object
const state = {    
    currentLocationId:null,
    answers:{},
    //leaderboard:[],
    score: 0,
    ranking: 0,
    position: null,
    endgamecomplete: false,
    currentRound: 0,   // the current round we are in
    dimmed: false,
    status: "setup", //"started", "endgame","finished"
}
// Actions 
const actions = {
    appState({commit},appState) {
        // round, dimmed, action
        commit('DIM_SCREEN',appState.dimmed);
    },
    initAnswers({commit,rootState}) {
        commit('INIT_ANSWERS',rootState.gamesettings.selected_locations)
    },
    setGameStatus({commit}, status) {
        console.log("set status:" + status)
        commit('SET_GAME_STATUS', status);
    },
    setCurrentLocationId({commit},id) {
        commit('SET_LOCATION_ID', id);
    },
    dimScreen({commit},bool) {
        commit('DIM_SCREEN',bool);
    },
    retrieveGroupResults({commit,rootState}) {
        let endpoint = process.env.VUE_APP_API + "results/" + rootState.user.roomname + '/' + rootState.user.groupname;

        return new Promise((resolve) => {
            axios.get(endpoint)
            .then((response) => {

                commit('SET_SCORE', response.data.currentScore);

                let results = response.data.assignmentResults;
                let ids = Object.keys(results);
                ids.forEach( (id) => {  

                    let result = (results[id]) ? "correct" : "incorrect";

                    commit('SET_ANSWER', {id:id, result: result});
                });

                resolve();
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message);
                }
                else {
                    console.log(error);
                }
                // resolve anyway, we can live without the group results
                resolve();
            })
        });  
    },
    synchroniseGroupResults({rootState},payload) {

        let endpoint = process.env.VUE_APP_API + "results/" + rootState.user.roomname + '/' + rootState.user.groupname;

        return new Promise((resolve,reject) => {
            axios.put(endpoint, payload)
            .then(() => {
                //dispatch('getLeaderBoard');
                resolve();
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data.message);
                }
                else {
                    reject(error);
                }
            })
        });        
    },
    setScore({commit,dispatch},score) {

        //let newscore = state.score + score;
        commit('SET_SCORE', score);

        let payload =  {
            id: 99,
            correct: true,
            score: score
        }

        dispatch('synchroniseGroupResults',payload);
    },
    setAnswer({commit,state,dispatch,rootState},answer) {

        commit('SET_ANSWER', answer);

        let score = 0;
        for (var id in state.answers) {
            // console.log(id + ":" + state.answers[id]);
            if (state.answers[id] === "correct"){
                score += rootState.gamesettings.pointsPerQuestion;
            }
        }
        commit('SET_SCORE', score);


        let payload =  {
            id: answer.id,
            correct: (answer.result === "correct"),
            score: score
        }

        dispatch('synchroniseGroupResults',payload);
    },
    setRanking({commit},ranking) {
        commit('SET_RANKING', ranking);
    },
    reset({commit}) {
        // console.log("GAME RESET")
        commit('RESET_GAME');
        //commit('INIT_LOCATIONS');
        // commit('SET_ENDGAME_COMPLETE',false);
    },
    setCurrentRound({commit},round) {
        commit('SET_CURRENT_ROUND', round);
    },
    
    setEndgameComplete({commit},bool) {
        commit('SET_ENDGAME_COMPLETE', bool);
    },    
}
// Mutations
const mutations = {
    DIM_SCREEN(state, bool) {
        state.dimmed = bool;
    },
    INIT_ANSWERS(state, locations) {
        // console.log(locations)
        // create an associative array that has empty values
        state.answers = {};

        locations.forEach( (id) => {
            //round.forEach( (id) => {
                console.log(id)
                Vue.set(state.answers, id, '');
            //});
        });
    },
    SET_GAME_STATUS(state, status) {
        state.status = status;
    }, 
    SET_LOCATION_ID(state, id) {
        state.currentLocationId = id;
    }, 
    SET_RANKING(state, ranking) {
        state.ranking = ranking;
    }, 
    SET_SCORE(state, score) {
        console.log("set score:" + score)
        // if (score > state.score) {
            state.score = score;
        // }
    }, 
    SET_ANSWER(state, {id, result}) {
        // possible values: correct, incorrect
      //  console.log(id + ":" + result)
        Vue.set(state.answers, id, result);
    },  
    RESET_GAME(state) {
        state.currentRound = 0;
        state.endgamecomplete = false;
        state.status = "setup";
    },
    SET_CURRENT_ROUND(state, round) {
        state.currentRound = round;
    },
    SET_ENDGAME_COMPLETE(state, bool) {
        state.endgamecomplete = bool;
    },
}

const getters = {
    dimmed: state => {
        return state.dimmed;
    }, 
    score: state => {
        return state.score;
    }, 
    ranking: state => {
        return state.ranking;
    }, 
    status: state => {
        return state.status;
    },
    gamestarted: state => {
        return (state.status === "started");
    },
    gamecomplete: state => {
        //console.log(state.results)
        let complete = true;

        for (var id in state.answers) {
            if (state.answers[id] === ""){
                complete = false;
                break;
            }
        }
        return complete;
    },
    roundcomplete: (state, getters, rootState, rootGetters) => {
        // console.log("round complete?")
        // console.log(rootState)
        // console.log(rootState.gamesettings)
        // console.log(rootGetters)
        // console.log(rootState.gamesettings.selected_rounds)
        // console.log(rootGetters["gamesettings/selected_rounds"]);
        let selected_rounds = rootGetters["gamesettings/selected_rounds"];
        //console.log(state.currentRound)
        let round = selected_rounds[state.currentRound];

        // console.log(round)
        // console.log(state.answers)
        let complete = true;
        for (var index in round) {
            let id = round[index];
            if (state.answers[id] === ""){
                
                complete = false;
                break;
            }
        }
        return complete;
    },
    numAnswers: (state) => {
        let num = 0;
        for (var id in state.answers) {
            // console.log(id + ":" + state.answers[id]);
            // we use 0 to initialise the student group name, so it is not counted
            if (id != 0 && state.answers[id] != ""){
                num++;
            }
        }
        return num;
    },
    currentAnswer: (state) => {
        if (state.currentLocationId){
            return state.answers[ state.currentLocationId ];    
        }
        else {
            return '';
        }
    }, 
    answers: state => {
        return state.answers;
    },    
    endgamecomplete: state => {
        return state.endgamecomplete;
    },
    currentQuestion: (state,getters,rootState) => {
        // get the questions for this location, based on the level
        //console.log(rootState.gamesettings.selected_level)
        let level = rootState.gamesettings.selected_level;
        let loc = getters.currentLocation;
        // console.log(loc)
        // console.log(level)
        if (loc) {
            let q = loc.questions.find(question => question.level === level.toString());
            // console.log(q)
            return q;    
        }
        else return null;
    },
    currentLocation: (state,getters,rootState) => {
        // get the images etc for this location
        if (state.currentLocationId) {
            return rootState.gamesettings.locations.find(location => location.id === state.currentLocationId);
        }
        else {
            return null;
        }
    },
    currentLocationId: (state) => {
        return state.currentLocationId;
    },
    currentRound: state => {
        return state.currentRound;
    },    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}