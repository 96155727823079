import Vue from 'vue'
import App from './App.vue'
import store from "./store";
import router from "./router";
import axios from 'axios'

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import '@/assets/css/tailwind.css'

import './registerServiceWorker'

import "@/assets/fonts/fonts.css";
import i18n from './i18n'
import io from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';

Sentry.init({
  Vue,
  environment: process.env.NODE_ENV,
  dsn: "https://05c98c6723794e8694e880d151738eae@o1069550.ingest.sentry.io/6064510",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "grondwetloop-app.acceptatie-prodemos.nl", "grondwetloop-app.prodemos.nl", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,
});



Vue.config.productionTip = false

const socket = io(process.env.VUE_APP_SOCKET,{
  autoConnect: false,
  secure:true
});

Vue.use( 
  VueSocketIOExt, 
  socket, 
  { store }
);

new Vue({
  store,
  router,
  data: {
    location: null
  },
  beforeCreate() {
    //this.$store.dispatch("clock/startClock");

    // get a headstart with the location 
    navigator.geolocation.getCurrentPosition((location) => {

      // activate the gps already
      console.log("got location!")
      console.log(location)
      // save it globally
      this.location = location;
    });
    // console.log("call initApp")
    let locfile = "static/content/questions/locations.json?ts=" + Date.now();
    axios
    .get(locfile)
    .then(response => {

        let options = {
          question_locations: response.data.locations,
          endgame_locations: response.data.endgame_locations,
          selectable_rounds : response.data.rounds,
          selectable_levels : response.data.levels
        }
        this.$store.dispatch("gamesettings/init", options);
    })
    .then(
      axios
      .get("static/content/settings.json?ts=" + Date.now())
      .then(response => {

        let defaults = {
          points_per_question: response.data.pointsPerQuestion,
          game_duration: response.data.defaulttime,
          endgame_duration: response.data.endgametime,
          endgame_playertime: response.data.endgameplayertime,
          hitdistance: response.data.hitdistance,
          markerpool: response.data.markerpool,
          mapprovider: response.data.mapprovider
        }
        this.$store.dispatch("gamesettings/defaults", defaults);
        this.$store.dispatch("setMasterCode", response.data.code);

      })
    )
    .catch(error => {
        console.log(error.response)
        //reject(error.response.data.message)
    })

    
    // this.$store.dispatch("game/getLocations")
    // .then(() => {
    //     this.$store.dispatch("initApp");
    // })
    // .catch(error => {
    //     console.log(error);
    // });

    //this.$store.commit('CHECK_LOCALSTORAGE');
  },

  render: h => h(App),
  i18n,

  created() {
    // disable contextmenu/long press
    //console.log(process.env.NODE_ENV)
    if (process.env.NODE_ENV === "production") {
      window.addEventListener("contextmenu", function(e) { e.preventDefault(); })
    }

    window.addEventListener('visibilitychange', () => {
      this.$store.dispatch('hardware/setSleep', document.hidden);
    });
    window.addEventListener('offline', () => {
      this.$store.dispatch('hardware/setConnected', false)
    });
    window.addEventListener('online', () => {
      this.$store.dispatch('hardware/setConnected', true)
    });
    
    navigator.connection.addEventListener('change', ()=>{
      this.$store.dispatch('hardware/connectionChange')
    })
    if(navigator.getBattery) {
      navigator.getBattery().then((battery) => {

        battery.addEventListener("chargingchange", ()=>{
          this.$store.dispatch('hardware/batteryChange',battery)
        }, false);
        battery.addEventListener("levelchange", ()=>{
          this.$store.dispatch('hardware/batteryChange',battery)
        }, false);

        this.$store.dispatch('hardware/batteryChange',battery)
      });
    }
    else {
      console.warn("Your browser does not have the navigator.mozBattery object");
    }
  }
}).$mount('#app')
