import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SetupUserType",
    //component: SetupUserType,
    component: () => import(/* webpackChunkName: "setup" */ '@/components/SetupUserType.vue')
  },
  {
    path: "/vwnw/:roomname/:user/:groupname",
    name: "SetupFromVWNW",
    //component: SetupUserType,
    component: () => import(/* webpackChunkName: "setup" */ '@/components/SetupFromVWNW.vue')
  },
  {
    path: "/setup/student",
    name: "SetupStudent",
    redirect: "/setup/student/session",
    component: () => import(/* webpackChunkName: "setup" */ '@/components/student/setup/SetupStudent.vue'),
    children: [
      {
        path: 'session',
        component: () => import(/* webpackChunkName: "student-setup" */ '@/components/student/setup/SetupSession.vue')
      },
      {
        path: 'room',
        component: () => import(/* webpackChunkName: "setup" */ '@/components/SetupRoomName.vue')
      },
      {
        path: 'group',
        component: () => import(/* webpackChunkName: "student-setup" */ "@/components/student/setup/SetupGroupName.vue")
      }
    ]
  },
  {
    path: "/setup/begeleider",
    name: "SetupBegeleider",
    redirect: "/setup/begeleider/room",
    component: () => import(/* webpackChunkName: "setup" */ '@/components/begeleider/setup/SetupBegeleider.vue'),
    children: [
      {
        path: 'room',
        component: () => import(/* webpackChunkName: "setup" */ '@/components/SetupRoomName.vue')
      },
      {
        path: 'time',
        component: () => import(/* webpackChunkName: "begeleider-setup" */ '@/components/begeleider/setup/SetDurationScreen.vue')
      },
      {
        path: 'level',
        component: () => import(/* webpackChunkName: "begeleider-setup" */ '@/components/begeleider/setup/SetLevelScreen.vue')
      },
      {
        path: 'rounds',
        component: () => import(/* webpackChunkName: "begeleider-setup" */ "@/components/begeleider/setup/SetRoundsScreen.vue")
      }
    ]
  },
  {
    path: "/begeleider/map/:option",  // setup-endgame, setup-locations, start-game, game
    name: "MapBegeleider",
    component: () => import(/* webpackChunkName: "map" */ "@/components/begeleider/MapScreenBegeleider.vue"),
  },
  {
    path: "/begeleider/endgame/:option", 
    name: "EndgameBegeleider",
    component: () => import(/* webpackChunkName: "map" */ "@/components/begeleider/EndGameScreenBegeleider.vue"),
  },
  {
    path: "/student/instructions/",
    name: "Instructions",
    props: {
      prefix: 'game',
      next: '/student/map/wait',
    },
    component: () => import(/* webpackChunkName: "map" */ "@/components/InstructionsScreen.vue"),
  },
  {
    path: "/student/map/:option",
    name: "Map",
    component: () => import(/* webpackChunkName: "map" */ "@/components/student/MapScreen.vue"),
  },
  {
    path: "/student/endgame/:option",
    name: "EndGame",
    component: () => import(/* webpackChunkName: "map" */ "@/components/student/EndGameScreen.vue"),
  },
];

const router = new VueRouter({
  //mode: 'history',
  routes,
});

export default router;
