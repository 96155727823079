<template>
  <div class="header">    
    <div class="toolbar">
        <!-- <div class="logo" @click="testStore"></div> -->
        <div class="help">
          <div v-if="(this.headerContent.hint != undefined && this.headerContent.hint != '')" :class="{'button-help':true, 'help-active':showHint}" @click="toggleHint">?</div>
        </div>

        <div  class="title" @click="detectTripleClick" >
          <h1 class="caption" v-html="caption"></h1>
          <h4 class="versioninfo" v-html="versioninfo"></h4>
        </div>

        <div class="quit">          
          <img v-if="this.roomname != ''" src="~@/assets/bt_quit.png" @dblclick="handleQuit"  />
        </div>
        <div class="status">
          <div class="battery-status">
            <span class="battery-percentage" v-html="battery_percentage"></span>
            <span class="battery-indicator">
              <img src="~@/assets/battery-outline.png" />
              <div class="battery-bar" ref="battery_bar" :style="style"></div>
            </span>
          </div>
          <div class="time">
            <span v-if="(countdownDisplayString != '' && ( (this.isMaster && this.$store.getters['game/status'] != 'endgame' ) || !this.isMaster))" ref="timer" class="timer" :class="(showWarningTime)?'blink':''">{{countdownDisplayString}}</span>
            <span v-if="(countdownDisplayString == '') || (this.isMaster && this.$store.getters['game/status'] === 'endgame')" ref="clock" class="clock">{{clockDisplayString}}</span> 
          </div>  
        </div>
        <div class="connection-indicator">
          <div class="connection-indicator-icon connection-indicator-offline" v-if="!connected"></div>
          <div :class="['connection-indicator-icon',connection_icon]" v-if="connected"></div>
        </div>

    </div>
    <transition name="fade">
      <div v-if="showHint" v-html="headerContent.hint" class="hint"></div>
    </transition>
    <!-- <div v-if="showWarningTime" class="warningtimer">{{countdownDisplayString}}</div> -->
  </div>
</template>

<script>
import {mapState} from 'vuex';
//import * as msgtypes from "./message-popup-types";

export default {
  data() {
    return {
      showHint:false,
      // timer: null,
      versioninfo: '',
      // roomname: "test",
      //isMaster: false,
      clockDisplayString: ''
    }
  },
  methods: {
    detectTripleClick() {
      clearTimeout(this.tripleclicktimer);
      this.clicks++;
      this.tripleclicktimer = setTimeout(() => {
          if(this.clicks === 1) {
              this.fullScreen();
              this.hideVersionInfo();
          }
          // if(this.clicks==2) {
          //     console.log("twice")
          // }
          if(this.clicks === 3) {
              this.showVersionInfo();
          }
          this.clicks = 0;
      }, 350);
    },
    hideVersionInfo() {
      this.versioninfo = '';
    },
    showVersionInfo(){
      var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(process.env.VUE_APP_GIT_DATE);

      let datestr = d.toLocaleDateString("nl-NL") + ",&nbsp;" + d.toLocaleTimeString("nl-NL");
      let version = process.env.VUE_APP_VERSION;

      this.versioninfo = "versie: " + version + "&nbsp;&nbsp;&nbsp;&nbsp;datum: " + datestr;
      this.versioninfo += "&nbsp;&nbsp;&nbsp;&nbsp;[" +process.env.VUE_APP_GIT_BRANCH + "] " + process.env.VUE_APP_GIT_SUBJECT ;

      // console.log( d.toLocaleDateString("nl-NL") + "," + d.toLocaleTimeString("nl-NL") );
      // console.log( process.env.VUE_APP_VERSION )
    },    
    handleQuit() {
      //console.log("quit")
      this.$emit("quit")
    },
    toggleHint() {
      this.showHint = !this.showHint;
    },
    fullScreen() {
      document.documentElement.webkitRequestFullScreen();
    },
    updateClock() {
        var date = new Date();
  
        var hour  = date.getHours();
        var min   = date.getMinutes();
        hour  = hour.toString().padStart(2, '0');
        min   =  min.toString().padStart(2, '0');

        // var sec = date.getSeconds();
        // if (sec%2===0){
          this.clockDisplayString = hour + ":" + min ;
        // }
        // else {
        //   this.clockDisplayString = hour + " " + min ;
        // }
    }
  },
  mounted() {
      // if (process.env.NODE_ENV != "production") {
      //   this.showVersionInfo();
      // }

      this.updateClock();
      setInterval(() => {
          this.updateClock();
      }, 1000 );
 
 },
 created() {
    this.tripleclicktimer = null;
    this.clicks = 0;
  },  
  computed: {
    caption() {
      // if (this.versioninfo != '') {
      //   return this.versioninfo;
      // }
      // else {
        return this.headerContent.caption;// + " - " + process.env.VUE_APP_VERSION;
      // }
    },
    appversion() {
      return process.env.VUE_APP_VERSION;
    },
    connection_icon() {
      let icon = 'wifi-connected';
      if (this.connectiontype === 'cellular') {
        icon = 'cellular-connected'
      }
      switch(this.effectivetype) {
        case 'slow-2g': {
          icon += + "-25";
          break;
        } 
        case '2g': {
          icon += + "-50";
          break;
        }
        case '3g': {
          icon += + "-75";
          break;
        }
        case '4g': {
          icon += + "-100";
          break;
        }
      }
      icon =  'indicator-' + icon;
      return icon;
    },
    battery_percentage() {
      return Math.round(this.battery_level * 100) + "%";
    },
    style () {
      let battery_color = "black";
      if ( this.battery_level  < .1) {
          battery_color = "red";
      }
      if (this.battery_charging) {
          battery_color = "green";
      }
      let style = {
         "width": (this.battery_full_size * this.battery_level) + "px",
         "background-color" : battery_color
      }
      return style;
    },
    showWarningTime() {
      //  if (!this.headerContent.isFinishScreen && !this.isMaster) {
        let parts = this.countdownDisplayString.split(":");
        let mins = Number(parts[0]);
        let secs = Number(parts[1]);

        //console.log(this.$store.getters["game/status"])
        if (this.$store.getters["game/status"] === "started") {
         // mins = 0;
          if (mins === 0 && secs === 59) {
              // console.log("a")
              window.navigator.vibrate(300);           
          }
          if (mins === 0 && secs === 30) {
              // console.log("b")

              window.navigator.vibrate(600);           
          }
          if (mins === 0 && secs <= 10) {
              // console.log("c")
              window.navigator.vibrate(100);           
          }
        }

        // console.log(mins);
        return (mins === 0);
    },
     ...mapState(['headerContent','showHeader','isConnected']),
    ...mapState('user', {
          isMaster: state => state.isMaster,
          roomname: state => state.roomname
      }),
    // ...mapState('clock', {
    //       clockDisplayString: state => state.clockDisplayString,
    //       countdownDisplayString: state => state.countdownDisplayString,
    //   }),
     ...mapState('clock', {
          //clockDisplayString: state => state.clockDisplayString,
          countdownDisplayString: state => state.countdownDisplayString,
      }),
    ...mapState('hardware', {
          connected: state => state.connected,
          connectiontype: state => state.connectiontype,
          effectivetype: state => state.effectivetype,
          battery_level: state => state.battery_level,
          battery_charging: state => state.battery_charging
      })
  },
  watch: {
    "headerContent" : function() {
      this.showHint = false;
    }
  }
};

</script>


<style scoped>
/* html {
  overflow: hidden !important;
} */

.header {
    background-color: var(--gray-color-dark);
    z-index:10;
}

.toolbar {
    /* position: absolute; */
    /* z-index:12; */
    width:100vw;
    height: 63px;
    display: grid;
    grid-template-columns:  56px 1fr 50px 80px 55px;

    box-shadow: 0px 1px 4px #00000029;

}

.quit {
  margin-top: calc(13px / var(--scale) );
  width: calc(58px / var(--scale) );
  height: calc(58px / var(--scale) );
}

.quit img {
  width:100%;
}

.hidden {
  display:none;
}

.disabled {
  opacity: .5;
}
.status {
   margin-top: calc(13px / var(--scale) );
}

.hint {
  /* position: absolute; */
  width:100vw;
  background-color: var(--purple-dark-color);
  font-size: 14px;
  color:white;
  padding:6px 57px;

  top: 63px;
  /* z-index:1; */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-enter-active, .slide-leave-active {
  /* transition: opacity .5s; */
   /* transition: top 63px; */
  transition: all 2s ease 0s;
  top: 63px;
}
.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  /* opacity: 0; */
   top: 0px;
}


/* .logo {
  background: url(../assets/logo-prodemos.svg) no-repeat #9f9383;
  background-size: 120px auto;
  background-position: center;
  height: 100%;
} */

.help {
  display: flex;
  /* background-color: red; */
}

.button-help {
  /* width:32px;
  height:32px; */

  width:calc(42px / var(--scale) );
  height:calc(42px / var(--scale) );

  background-color: var(--purple-middle-color);
  margin:auto;
  font-family: 'Fira Sans Medium';
  /* font-weight: 500;  */
  /* font-size:27px; */
  font-size: calc(36px / var(--scale) );

  text-align: center;
  color:white;
}

.help-active {
    background-color: var(--purple-dark-color);
}



.versioninfo {
  /* display: flex; */
  height: 100%;
  overflow: hidden;
  /* border:1px solid red; */
  white-space: nowrap;
  margin-right:10px;
  /* background-color: blue; */
}



.title {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  /* border:1px solid red; */
  white-space: nowrap;
  margin-right:10px;
}

.caption {
  font-family: 'Fira Sans Bold';
  color:var(--purple-dark-color);
  font-size: calc(41px / var(--scale) );
  line-height: calc(41px / var(--scale) );
  margin:17px 0 0 0;
  /* background-color: blue; */
}

.groupname {
  color:black;
  font-size: calc(35px / var(--scale) );
}
.versioninfo {
  margin-top: -3px;
  color:var(--dark-color);
  font-size: calc(15px / var(--scale) );
}

.show {
  display: block;
}

.hide {
  display: none;  
  /* background-color: red; */
}


.battery-status {
  margin-bottom:5px;
  margin-left: 20px;
  /* text-align: center; */
  font-family: 'SFProText-Medium';
  font-size: calc(12px / var(--scale) );
  /* background-color: greenyellow; */
}


.battery-indicator {
  position: relative;
  margin-left: 4px;
  top:1px;
}

.battery-indicator  img {
  position: absolute;
  width: calc(30px / var(--scale) );
}

.battery-bar {
  position: absolute;
  left:4px;
  top:2px;
  width: calc(19px / var(--scale) );
  height: calc(8px / var(--scale) );
  background-color: black;
}

.time { 
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  font-family: 'Fira Mono Bold';
  font-size: calc(26px / var(--scale) );

  margin-top:0;
}

.clock {
  color: var(--dark-text-color);
}

.timer {
  color: var(--purple-dark-color);
}

.connection-indicator {
  /* width:20px;
  height:20px;
  border-radius: 50%;
  margin-top:10px;
  margin-left:25px; */


  margin-top: calc(10px / var(--scale) );
  margin-left: -2px;
  position: relative;
  /* background-color:blue; */
}

/*   <div class="connection-indicator">
          <div :class="['connection-indicator-icon',connection_icon]" ></div>
          <div class="connection-indicator_icon connection-indicator-offline" ></div>
        </div> */

.connection-indicator-icon {
  position: absolute;
  width: calc(54px / var(--scale) );
  height: calc(54px / var(--scale) );
  background-size:contain;
}
.connection-indicator-offline {
  background-image : url('~@/assets/connection/disconnected.png');
}
.indicator-wifi-connected-100 {
  background-image : url('~@/assets/connection/wifi_connected_100.png');
}
.indicator-wifi-connected-75 {
  background-image : url('~@/assets/connection/wifi_connected_75.png');
}
.indicator-wifi-connected-50 {
  background-image : url('~@/assets/connection/wifi_connected_50.png');
}
.indicator-wifi-connected-25 {
  background-image : url('~@/assets/connection/wifi_connected_25.png');
}

.indicator-cellular-connected-100 {
  background-image : url('~@/assets/connection/cellular_connected_100.png');
}
.indicator-cellular-connected-75 {
  background-image : url('~@/assets/connection/cellular_connected_75.png');
}
.indicator-cellular-connected-50 {
  background-image : url('~@/assets/connection/cellular_connected_50.png');
}
.indicator-cellular-connected-25 {
  background-image : url('~@/assets/connection/cellular_connected_25.png');
}



/* .connection-indicator img {
  width:100%;
} */
.online {
  background-color: green;
}

.offline {
  background-color: red;
}

.blink {
  animation: blink-animation .5s steps(2, start) infinite;
  padding:2px;
  /* transition: all; */
}

/* .warningtimer {
  font-family: 'Fira Mono Bold';
  color: var(--purple-dark-color);
  font-size: calc(50px / var(--scale) );
  margin-top: calc(20px / var(--scale) );
  margin-left: calc(1090px / var(--scale) );
  animation: blink-animation .5s steps(2, start) infinite;
} */

@keyframes blink-animation {
  from {
    transform: scale(0.9);    
    color: white;
  }
  to {
    transform: scale(1.3);
    color: black;
    /* background-color: black; */
  }
}

</style>
