/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
      // BVB 24/09/20 this enables the download button
      // setInterval(() => {
      //   registration.update();
      // }, 1000 * 60 * 60); // e.g. hourly checks
    },
    cached (registration) {
      console.log('Content has been cached for offline use.')
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
    },
    updatefound (registration) {
      console.log('New content is downloading.')
      document.dispatchEvent(
        new CustomEvent('swUpdating', { detail: registration })
      );
    },
    updated () {
      console.log('New content is available; please refresh.');
      // console.log("Oh well, I will do it for you")
      window.location.reload(true);
      // BVB 24/09/20 this enables the download button
      // document.dispatchEvent(
      //   new CustomEvent('swUpdated', { detail: registration })
      // );
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
