
// State object
const state = {
    //clockDisplayString: '',
    countdownDisplayString: '',
    countdown:0,
    ticks: 0,
    timer:null,
    countdownComplete:false
}
// Actions 
const actions = {
    // startClock({commit}) {
    //     setInterval(() => {
    //         commit('UPDATE_CLOCK')
    //     }, 1000 );
    // },
    clearTimer({commit}) {
        commit('CLEAR_TIMER');
    },
    pauseTimer({ commit, dispatch, state },bool) {
        if (bool) {
            commit('PAUSE_TIMER');
        }
        else {
            if (state.countdown > 0) {
                dispatch('createTimer');
            }
        }
    },
    createTimer({commit,state}) {
        
        let timer = setInterval(() => {
            
            //var now = new Date().getTime();
            
            // Find the distance between now and the count down date
            //var distance = state.countdownDate - now;
            commit('TICK');
            let distance = state.countdown - state.ticks;
    
            // If the countdown is over, write some text 
            if (distance < 0) {
                //dispatch('activityTimesUp',null,{root:true});
                commit('COUNTDOWN_COMPLETE',true);
                commit('CLEAR_TIMER');
                // dispatch('screen/returnToProgram',null,{root:true});
            }
            else {
                commit('UPDATE_TIMER', distance);
            }

        }, 1000 ) ;

        commit('SET_TIMER', timer);
    },
    startTimer ({commit,dispatch},countdown) {   
        //console.log("START:" + countdown)
        // start a countdown timer (countdown is now in seconds)
        if (countdown) {
            //let today = new Date();

            //let countdown = time * 60;  // minutes * 60 seconds // today.setMinutes(today.getMinutes() + time);

            if (process.env.NODE_ENV === "development") {
                //countdown = 60; // 10 secs for testing
                //countdown = 10;
            }
            commit('COUNTDOWN_COMPLETE',false);

            commit('CLEAR_TIMER');
            commit('SET_COUNTDOWN',countdown);
            commit('UPDATE_TIMER', countdown);
  
            dispatch('createTimer');
        }      
    } 
}
// Mutations
const mutations = {
    // UPDATE_CLOCK(state) {
    //     var date = new Date();
  
    //     var hour  = date.getHours();
    //     var min   = date.getMinutes();
    //     hour  = hour.toString().padStart(2, '0');
    //     min   =  min.toString().padStart(2, '0');
    //     state.clockDisplayString = hour + ":" + min ;
    // },
    TICK (state) {
        state.ticks += 1;
    },  
    SET_COUNTDOWN (state, cd) {
        state.countdown = cd;
    },  
    SET_TIMER (state, timer) {
        // 13-10-2020 make sure there is never more than one timer running
        clearInterval(state.timer);
        state.timer = timer;
    },
    CLEAR_TIMER (state) {
        clearInterval(state.timer);
        state.ticks = 0;
        state.countdown = 0;
        state.countdownDisplayString = '';
    },
    PAUSE_TIMER (state) {
        clearInterval(state.timer);
        // state.countdownTime = '';
    },
    UPDATE_TIMER (state, distance) {
        var minutes = Math.floor((distance % (60 * 60)) /  60);
        var seconds = Math.floor((distance % (60)) );

        minutes = minutes.toString().padStart(2, '0');
        seconds = seconds.toString().padStart(2, '0');
        
        let timeleft = minutes + ":" + seconds;

        state.countdownDisplayString = timeleft;
    },
    COUNTDOWN_COMPLETE (state, bool) {
        //console.log("countdown complete:" + bool)
        state.countdownComplete = bool;
    },
}

const getters = {
    countdownComplete: state => {
        //console.log("countdown complete?" + state.countdownComplete)

        return state.countdownComplete;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}