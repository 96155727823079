import axios from 'axios'

const TIME_SIZE = 6;
const LEVEL_SIZE = 2;
const ENDGAME_SIZE = 1;
const ROUNDS_TYPE_SIZE = 1;


// State object
const state = {
    locations: [],    // all the locations with their details
    selected_locations: [], // the locations from a round that where selected by the begeleider
    endgame_locations: [],    
    selected_endgame_location: "0",
    rounds: [],  // the rounds sets to choose from: 1 round with all locations, or 2 rounds, locations are referenced by id
    selected_round_index: 0,
    levels: [],  // the levels to choose from   
    selected_level: "0",
    duration: 25,
    pointsPerQuestion: 10,
    code: '',
    endgameduration: 5,
    endgameplayertime: 10,
    hitdistance:15,
    markerpool:4,
    mapprovider:''
}

// Actions 
const actions = {
    // startGame({commit}) {
    //     commit('START_GAME');
    // },

    encodeGameInfo({commit,state}) {
        let questionBits = 0;

        // Loop over the array of selected questions.
        // Use the question ID to shift the amount of bits to the left and set the bit to 1.
        // It's important each question has an ID starting from zero and increments with 1.
        for(let i = 0; i < state.selected_locations.length; i++)
            questionBits |= (1 << state.selected_locations[i]);

        // Store end game info.
        questionBits <<= ENDGAME_SIZE;
        questionBits |= state.selected_endgame_location;

        // Store the amount of rounds;
        questionBits <<= ROUNDS_TYPE_SIZE;
        questionBits |= state.selected_round_index;
            
        // Store level info.
        questionBits <<= LEVEL_SIZE;
        questionBits |= state.selected_level;

        // Store time info.
        questionBits <<= TIME_SIZE;
        questionBits |= state.duration;

        let code = questionBits.toString(36).toUpperCase();
        commit('SET_CODE', code );
    },
    decodeGameInfo ({commit}, code) {

        console.log("decode:" + code);
        // Parse the code to a number.
        if(typeof code === "string")
            code = parseInt(code, 36);

        const timeBits = parseInt("1".repeat(TIME_SIZE), 2);
        const levelBits = parseInt("1".repeat(LEVEL_SIZE), 2);
        const endgameBits = parseInt("1".repeat(ENDGAME_SIZE), 2);
        const roundBits = parseInt("1".repeat(ROUNDS_TYPE_SIZE), 2);
        
        const time = code & timeBits;                                       // Extract the last 10 bits of the number, yielding the time in minutes.
        const level = (code >> TIME_SIZE) & levelBits;                       // Shift the code 10 bits to the right and extract the last 2 bits, yielding the level number.
        const rounds = (code >> TIME_SIZE + LEVEL_SIZE) & roundBits;
        const endgame = (code >> TIME_SIZE + LEVEL_SIZE + ROUNDS_TYPE_SIZE) & endgameBits;       // Shift the code 12 bits to the right and extract the last digit, yielding the id of the end question.
        const questions = (code >> TIME_SIZE + LEVEL_SIZE + ROUNDS_TYPE_SIZE + ENDGAME_SIZE);     // Shift the code 13 bits to the right and we're left with the 18 bits indicating which questions should be included.

        const questionLen = questions.toString(2).length;
        
        // Find all selected questions.
        const questionIDs = Array.from(questionLen);
        for(let i = questionLen - 1; i >= 0; i--)
        {
            if(questions >> i & 1)
                questionIDs.push(i);
        }

        console.log("time:" + time);
        console.log("level:" + level);
        console.log("endgame:" + endgame);
        console.log("round_index:" + rounds);
        console.log("locations:");
        console.log(questionIDs);


        commit('SET_CODE', code );

        commit('SET_DURATION', time);
        commit('SET_LEVEL', level);
        commit('SET_SELECTED_ROUNDS',rounds);
        commit('SET_SELECTED_ENDGAME_LOCATION', endgame);

        commit('SET_SELECTED_LOCATIONS',questionIDs);  
        

        return new Promise((resolve) => {
            resolve();
        }); 
    },
    init({commit}, options){
        commit('SET_QUESTION_LOCATIONS', options.question_locations);

        // all locations are selected initially
        let location_ids = [];
        options.question_locations.forEach(location => {
            location_ids.push(location.id);
        });
        commit('SET_SELECTED_LOCATIONS', location_ids);

        commit('SET_ENDGAME_LOCATIONS', options.endgame_locations);

        commit('SET_ROUNDS', options.selectable_rounds);
        commit('SET_LEVELS', options.selectable_levels);
    },
    defaults({commit}, defaults) {
        commit('SET_DURATION', defaults.game_duration);
        commit('SET_POINTS_PER_QUESTION', defaults.points_per_question);
        
        commit('SET_ENDGAME_DURATION', defaults.endgame_duration);

        if (process.env.VUE_APP_GIT_BRANCH === "development") {
            commit('SET_ENDGAME_PLAYER_TIME', 10 );
        }
        else {
            commit('SET_ENDGAME_PLAYER_TIME',defaults.endgame_playertime);
        }
                   
        commit('SET_MARKERPOOL',defaults.markerpool);
        commit('SET_MAPPROVIDER',defaults.mapprovider);
        commit('SET_HITDISTANCE',defaults.hitdistance);

    },
    info({commit}, info) {
        if (info.questions.length > 0) {
            // there was actually something saved
            let questions = info.questions.map(String);
            commit('SET_SELECTED_LOCATIONS',questions);
            commit('SET_DURATION', info.time);
            commit('SET_LEVEL', info.level);
            commit('SET_SELECTED_ROUNDS', info.rounds);    
            commit('SET_SELECTED_ENDGAME_LOCATION', info.endGame);
        }
    },
    code({commit}, code) {
        commit('SET_CODE', code);    
    },
    updateSelectedLocations({commit, rootState},selected_locations_ids) {
        commit('SET_SELECTED_LOCATIONS',selected_locations_ids);

        let payload =  {
            questions: selected_locations_ids,
        }

        let endpoint = process.env.VUE_APP_API + "game-info/" + rootState.user.roomname + '/questions';

        return new Promise((resolve,reject) => {
            axios.put(endpoint, payload)
            .then(() => {
                resolve();
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data.message);
                }
                else {
                    reject(error);
                }
            })
        });           
    },
    setGameDuration({commit,rootState},duration) {
        commit('SET_DURATION', duration);

        let payload =  {
            duration: duration,
        }

        let endpoint = process.env.VUE_APP_API + "game-info/" + rootState.user.roomname + '/duration';

        return new Promise((resolve,reject) => {
            axios.put(endpoint, payload)
            .then(() => {
                resolve();
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data.message);
                }
                else {
                    reject(error);
                }
            })
        });   

    },
    setGameLevel({commit,rootState},level) {
        commit('SET_LEVEL', level);

        let payload =  {
            level: level,
        }

        let endpoint = process.env.VUE_APP_API + "game-info/" + rootState.user.roomname + '/level';

        return new Promise((resolve,reject) => {
            axios.put(endpoint, payload)
            .then(() => {
                resolve();
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data.message);
                }
                else {
                    reject(error);
                }
            })
        });   

    },
    setEndGameLocation({commit,rootState},index) {
        commit('SET_SELECTED_ENDGAME_LOCATION', index);

        let payload =  {
            endgame: index,
        }

        let endpoint = process.env.VUE_APP_API + "game-info/" + rootState.user.roomname + '/end-game';

        return new Promise((resolve,reject) => {
            axios.put(endpoint, payload)
            .then(() => {
                resolve();
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data.message);
                }
                else {
                    reject(error);
                }
            })
        });          
    },
    setRounds({commit,rootState},index) {
        commit('SET_SELECTED_ROUNDS', index);

        let payload =  {
            rounds: index,
        }

        let endpoint = process.env.VUE_APP_API + "game-info/" + rootState.user.roomname + '/rounds';

        return new Promise((resolve,reject) => {
            axios.put(endpoint, payload)
            .then(() => {
                resolve();
            })
            .catch(error => {
                if (error.response) {
                    reject(error.response.data.message);
                }
                else {
                    reject(error);
                }
            })
        });   
    },
}
// Mutations
const mutations = {
    SET_QUESTION_LOCATIONS(state, data) {
        state.locations = data;
    },
    SET_ENDGAME_LOCATIONS(state, data) {
        state.endgame_locations = data;
    },
    SET_SELECTED_ENDGAME_LOCATION(state, index) {

        state.selected_endgame_location = index.toString();

        //state.rounds = Array.from( state.originalrounds );

        // console.log("rounds in original order:");
        // console.log(state.rounds);
        
        state.rounds = JSON.parse(JSON.stringify(state.originalrounds)); 

        console.log("endgame location:" + index);
        console.log("selected round index:" + state.selected_round_index );


        console.log("current first round locations:");
        for (let i=0; i < state.rounds[ state.selected_round_index ][0].length; i++) {
            console.log( state.rounds[ state.selected_round_index ][0][i] )
        }

        // switch rounds when we have 2 rounds and endgame location is at Thorbecke's side
        if (state.rounds[ state.selected_round_index ].length > 1) {
            if (state.selected_endgame_location == 0) {
                console.log("===switch===")

                let rounds = state.rounds[ state.selected_round_index ];
                let tmp =  JSON.parse(JSON.stringify(rounds[0]));
                
                state.rounds[ state.selected_round_index ][0] = JSON.parse(JSON.stringify(rounds[1]));
                state.rounds[ state.selected_round_index ][1] = tmp;

            }
        }
        // console.log("current round locations:" );
        // console.log(state.rounds[ state.selected_round_index ]);

        console.log("final first round locations:");
        for (let i=0; i < state.rounds[ state.selected_round_index ][0].length; i++) {
            console.log( state.rounds[ state.selected_round_index ][0][i] )
        }

        // console.log("rounds in working order:")
        // console.log(state.rounds);
        // console.log(state.originalrounds)
    },
    SET_SELECTED_LOCATIONS(state, selected_location_ids) {
        state.selected_locations = selected_location_ids.map(String);
    },
    SET_POINTS_PER_QUESTION(state, points) {
        state.pointsPerQuestion = points;
    },  
    SET_SELECTED_ROUNDS(state, index) {
        console.log("SET SELECTED ROUNDS:" + index);
        state.selected_round_index = index;
    },  
    SET_ROUNDS(state, rounds) {
        // this is the master vaue, we set the rounds property when the endgame location is selected
        state.originalrounds = JSON.parse(JSON.stringify(rounds)); 

        // just in case that step is skipped
        state.rounds = JSON.parse(JSON.stringify(rounds)); 
    },  
    SET_LEVELS(state, levels) {
        state.levels = levels;
    },  
    SET_LEVEL(state, level) {
        //console.log("SET LEVEL:" + level);

        state.selected_level = level.toString();
    },
    SET_DURATION(state, duration) {
        state.duration = duration;
    },
    SET_CODE(state, code) {
        state.code = code;
    },
    SET_ENDGAME_DURATION(state, duration) {
        state.endgameduration = duration;
    },
    SET_ENDGAME_PLAYER_TIME(state, time) {
        state.endgameplayertime = time;
    },
    SET_HITDISTANCE (state, distance) {
        state.hitdistance = distance;
    },
    SET_MARKERPOOL (state, pool) {
        state.markerpool = pool;
    },    
    SET_MAPPROVIDER (state, p) {
        state.mapprovider = p;
    },    
}

const getters = {
    // gamestarted: state => {
    //     return state.gamestarted
    // },
    code: state => {
        return state.code
    },
    locations: state => {
        return state.locations
    },
    selectedEndGameLocation: state => {
        console.log( state.endgame_locations[state.selected_endgame_location] )
        return state.endgame_locations[state.selected_endgame_location];
    },
    selectedEndgameLocationId: state => {
        return state.selected_endgame_location
    },
    endgameLocations: state => {
        return state.endgame_locations
    },
    endgameduration: state => {
        return state.endgameduration
    },
    endgameplayertime: state => {
        return state.endgameplayertime
    },
    duration: state => {
        return state.duration;
    },
    levels: state => {
        return state.levels
    },
    level: state => {
        return state.selected_level
    },
    selected_rounds: state => {
        return state.rounds[state.selected_round_index];
    },
    selected_locations: state => {
        return state.selected_locations;
    },
    numberOfRounds: state => {
        return state.rounds[state.selected_round_index].length;
    },
    selectedLevel: state => {
        return state.levels.find(level => level.id === state.selected_level );
    },
    rounds: state => {
        return state.rounds;
    },
    pointsPerQuestion: state => {
        return state.pointsPerQuestion;
    },
    // idsInCurrentRound: state => {
    //     return state.selected_rounds[state.currentRound];
    // },
    hitdistance: state => {
        return Number(state.hitdistance);
    },
    markerpool: state => {
        return Number(state.markerpool);
    },  
    mapprovider: state => {
        return state.mapprovider;
    },  
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}